import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    status: "not-authenticated",
    _id: null,
    document: null,
    role: "USER_ROLE",
    errorMessage: null,
  },
  reducers: {
    login: (state, { payload }) => {
      state.status = "authenticated";
      state._id = payload._id;
      state.document = payload.document;
      state.role = "ADMIN_ROLE";
      state.errorMessage = null;
    },
    logout: (state) => {
      state.status = "not-authenticated";
      state._id = null;
      state.document = null;
      state.role = "USER_ROLE";
    },
    checkingCredentials: (state) => {
      state.status = "checking";
      state.errorMessage = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { login, logout, checkingCredentials } = authSlice.actions;
