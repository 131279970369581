import ToastContainer from "./Presentation/components/Notifications/ToastContainer";
import RouterPage from "./Presentation/router/RouterPage";
import wa from "./Assets/wa/whatsapp.png";
import { motion } from "framer-motion";

const App = () => {
  const abrirWhatsApp = () => {
    const waNumber = "573117559275";
    const enlaceWhatsApp = `https://api.whatsapp.com/send?phone=${waNumber}`;
    window.open(enlaceWhatsApp, "_blank");
  };

  return (
    <div>
      <motion.button
        onClick={abrirWhatsApp}
        className="fixed sm:bottom-20 xs:bottom-20 bottom-5 right-5 bg-green-500 hover:bg-green-600 text-white p-4 rounded-full shadow-xl z-50"
        initial={{ scale: 1 }}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
      >
        <img src={wa} alt="wa" width={30} />
      </motion.button>
      <ToastContainer />
      <RouterPage />
    </div>
  );
};

export default App;
