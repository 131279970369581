import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { AuthRouter } from "./AdminRoutes/AdminRoutes";

const HomeView = lazy(() => import("../views/Home/HomeView"));
const ContactView = lazy(() => import("../views/Contact/ContactView"));
const RentView = lazy(() => import("../views/Rent/RentView"));
const SaleView = lazy(() => import("../views/Sale/SaleView"));
const AssessView = lazy(() => import("../views/assess/AssessView"));
const HireView = lazy(() => import("../views/Hire/HireView"));
const UsView = lazy(() => import("../views/us/UsView"));
const VisitUsView = lazy(() => import("../views/VisitUs/VisitUsView"));
const SearchView = lazy(() => import("../views/Search/SearchView"));
const ItemView = lazy(() => import("../views/item/ItemView"));
const LoginView = lazy(() => import("../views/auth/Login/LoginView"));
const ListAlquilerView = lazy(() => import("../views/list/Alquiler/AlquilerView"));
const ListArriendoView = lazy(() => import("../views/list/Rent/ArriendoView"));
const UploadView = lazy(() => import("../views/dashboard/upload/upload"));
const ContactDashboardView = lazy(() => import("../views/dashboard/contact/contact"));

const RouterPage = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<HomeView />} />
        <Route path="/contacto" element={<ContactView />} />
        <Route path="/servicios/arriendo" element={<RentView />} />
        <Route path="/servicios/venta" element={<SaleView />} />
        <Route path="/servicios/avaluos" element={<AssessView />} />
        <Route path="/servicios/amoblados" element={<HireView />} />
        <Route path="/nosotros/nosotros" element={<UsView />} />
        <Route path="/nosotros/visitenos" element={<VisitUsView />} />
        <Route path="/buscar" element={<SearchView />} />
        <Route path="/buscar/inmueble/:id" element={<ItemView />} />
        <Route path="/auth/login" element={<LoginView />} />
        <Route path="/amoblados" element={<ListAlquilerView />} />
        <Route path="/arriendo" element={<ListArriendoView />} />
        <Route element={<AuthRouter />}>
          <Route path="/dashboard/upload" element={<UploadView />} />
          <Route path="/dashboard/contact" element={<ContactDashboardView />} />
        </Route>
      </Routes>
    </>
  );
};

export default RouterPage;
