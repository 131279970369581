import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import persistStore from "redux-persist/es/persistStore";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { store } from "./Domain/storage/storage";
import { Suspense } from "react";
import TransitionEffect from "./Presentation/components/Shared/Effects/TransitionEffect";

const persistor = persistStore(store);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <PersistGate persistor={persistor}>
    <Provider store={store}>
      <Suspense fallback={<TransitionEffect />}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Suspense>
    </Provider>
  </PersistGate>
);
